import { useEffect, useState } from 'react';
import { AutoCompleteChangesChecked } from "../Components/AutoCompleteChangesChecked";
import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { Multiselect } from '../Components/FormElements/Multiselect';
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { InputNumberChecked } from "../Components/InputNumberChecked";
import { useConfigurationsList } from "../Persistence/ConfigurationsContext";
import { useCurrentUser } from "../Persistence/CurrentUserContext";
import { useOrganizationsSelectionList } from "../Persistence/OrganizationsContext";
import { useRegionsList, useRegionsSelectionList } from '../Persistence/RegionsContext';
import { useTaskareasList, useTaskareasSelectionList } from '../Persistence/TaskareasContext';
import { logDefault } from '../Utils/logger';

const CLASSNAME = 'UserForm'

export const emptyUser = {
	id: null,
	email: '', username: '', password: '', displayname: '', permissions: '', roles: '', isAdmin: 0, loginAllowed: 1, fte: 1, regions: [], taskareas: [],
	active: 1, version: 1, forceChangePassword: 1, lastname: '', firstname: '', mobilenumber: '', phonenumber: '', phonenumberbusiness: '', organization: ''
}

export const UserForm = ({ formik, profileMode }) => {

	const currentUser = useCurrentUser();
	const configurationsList = useConfigurationsList();
	const regionsListSelection = useRegionsSelectionList();
	const regionsListAll = useRegionsList();
	const taskareaListAll = useTaskareasList();
	const taskareaListSelection = useTaskareasSelectionList();
	const organizationsSelectionList = useOrganizationsSelectionList();

	const [regionOptions, setRegionOptions] = useState(regionsListSelection)
	const [taskareaOptions, setTaskareaOptions] = useState(taskareaListAll)

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values', formik.values)
	})

	useEffect(() => {
		if (regionsListAll && regionsListSelection) {
			const regionOptionsList = JSON.parse(JSON.stringify(regionsListSelection));
			logDefault(CLASSNAME, 'info', 'regionOptionsList 1:' + regionOptionsList.length);
			formik.values.regions.forEach(entry => {
				const listEntry = regionsListAll.find(e => e.id === entry.id);
				if (!regionOptionsList.find(e => e.id === entry.id)) {
					logDefault(CLASSNAME, 'info', 'add entry to regionOptionsList:' + entry.id);
					regionOptionsList.push(listEntry);
				}
			})
			setRegionOptions(regionOptionsList)
			logDefault(CLASSNAME, 'info', 'regionOptionsList 2:' + regionOptionsList.length);
		}
	}, [regionsListSelection, regionsListAll])

	useEffect(() => {
		if (taskareaListAll && taskareaListSelection) {
			const optionsList = JSON.parse(JSON.stringify(taskareaListSelection));
			logDefault(CLASSNAME, 'info', 'optionsList 1:' + optionsList.length);
			formik.values.taskareas.forEach(entry => {
				const listEntry = taskareaListAll.find(e => e.id === entry.id);
				if (!optionsList.find(e => e.id === entry.id)) {
					logDefault(CLASSNAME, 'info', 'add entry to optionsList:' + entry.id);
					optionsList.push(listEntry);
				}
			})
			setTaskareaOptions(optionsList)
			logDefault(CLASSNAME, 'info', 'optionsList 2:' + optionsList.length);
		}
	}, [taskareaListSelection, taskareaListAll])

	return (
		!formik ? '' : <>
			<div className='col'>
				<div className="formgrid grid">
					<InputChangesChecked id='firstname' autoFocus={true} type="text" formik={formik} label='Vorname' setShowDialog={() => { }} />
					<InputChangesChecked id='lastname' tabIndex={1} type="text" formik={formik} label='Nachname' setShowDialog={() => { }} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='phonenumber' tabIndex={2} type="text" formik={formik} label='Telefon' setShowDialog={() => { }} />
				</div>
				<div className="formgrid grid">
					<InputChangesChecked id='mobilenumber' tabIndex={3} type="text" formik={formik} label='Mobil' setShowDialog={() => { }} />
					<div className="field col mr-3" />
				</div>
				{currentUser.permissions !== 'ADMIN' || profileMode ? '' :
					<>
						<div className="formgrid grid">
							<AutoCompleteChangesChecked tabIndex={4} label={'Organisation'} id={'organization'} itemLabel={'name'} formik={formik} dropdown={true} forceSelection={true} suggestionsList={organizationsSelectionList} setShowDialog={() => { }} />
						</div>
						<div className="formgrid grid">
							<InputChangesChecked id='username' tabIndex={5} type="text" formik={formik} label='Anmeldename' setShowDialog={() => { }} />
							<DropdownChangesChecked tabIndex={6} label={'Rolle'} id={'permissions'} editmode={true} itemLabel={'display'} formik={formik} options={configurationsList.RolesList} setShowDialog={() => { }} handleChange={formik.handleChange} filter={false} />
						</div>
						<div className="formgrid grid">
							<InputChangesChecked tabIndex={7} id='email' label='E-Mail' type="text" formik={formik} setShowDialog={() => { }} />
						</div>
						<div className="formgrid grid">
							<DropdownChangesChecked tabIndex={8} className={'w-6'} editmode={currentUser.id !== formik.values.id} label='Benutzer kann sich anmelden' id={'loginAllowed'} itemLabel='display' formik={formik} options={[{ display: 'Ja', value: 1 }, { display: 'Nein', value: 0 }]} setShowDialog={() => { }} filter={false} onChange={(value) => { if (!value) { formik.setFieldValue('fte', 0) } }} />
							{!formik.values.loginAllowed ? <div className="field col mr-3" /> : <InputNumberChecked id='fte' type="number" formik={formik} label='FTE' setShowDialog={() => { }} className='w-3rem' digits={2} showButtons={true} step={0.1} max={1} />}
						</div>
						<div className="formgrid grid">
							<Multiselect id='regions' options={regionOptions} filter={true} editmode={true} placeholder="Regionen wählen" itemLabel="name" showClear={false} label='Regionen' formik={formik} display='chip' />
							<Multiselect id='taskareas' options={taskareaOptions} filter={true} editmode={true} placeholder="Aufgabenbereiche wählen" itemLabel="name" showClear={false} label='Aufgabenbereiche' formik={formik} display='chip' />
						</div>

					</>}

				{formik.values.id ? '' :
					<div className="formgrid grid">
						<InputChangesChecked id='password' tabIndex={9} label='initiales Passwort' type="text" formik={formik} setShowDialog={() => { }} />
					</div>}
			</div>
		</>
	)
}

export const validateUser = (data) => {
	let errors = {};
	if (!data.email) {
		errors.email = 'E-Mail muss gesetzt und eindeutig sein';
	}
	if (!data.username) {
		errors.username = 'Anmeldenamen muss gesetzt und eindeutig sein';
	}
	if (!data.id && !data.password) {
		errors.password = 'Ein Passwort muss beim Anlegen gesetzt werden';
	}
	if (!data.isAdmin && !data.organization) {
		errors.organization = 'Organisation muss ausgefüllt sein';
	}
	if (!data.permissions) {
		errors.permissions = 'Rolle muss ausgefüllt sein';
	}
	return errors;
}
